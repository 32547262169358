<template>
  <b-container fluid>
    <b-tabs v-model="currentTab" class="mt-3">
      <b-tab
        :key="tab.title"
        lazy
        :title="tab.title"
        @click="chooseTab(tab)"
        v-for="tab in tabs"
      >
        <b-card>
          <b-form @submit.prevent="onSearch(tab)">
            <b-row>
              <template
                v-for="(searchEntity, searchIndex) of tab.searchForm
                  .searchEntities"
              >
                <b-col cols="6" class="mb-4">
                  <b-select
                    v-model="searchEntity.searchField"
                    :options="searchFields"
                  />
                </b-col>
                <b-col cols="6" class="mb-4">
                  <b-input-group>
                    <b-input
                      v-model.trim="searchEntity.searchValue"
                      @change="changeField(searchEntity, tab)"
                    />
                    <!--                    <b-input-->
                    <!--                      v-model.trim="searchEntity.searchValue"-->
                    <!--                      @change="-->
                    <!--                        initializeOtherFields(tab);-->
                    <!--                        changeField(searchEntity, tab);-->
                    <!--                      "-->
                    <!--                    />-->
                    <b-input-group-append>
                      <b-select v-model="searchEntity.searchType">
                        <b-select-option value="=">Perfect</b-select-option>
                        <b-select-option value="like">Partial</b-select-option>
                        <b-select-option value="not">Not</b-select-option>
                        <b-select-option value=">=">&gt;=</b-select-option>
                        <b-select-option value="<=">&lt;=</b-select-option>
                      </b-select>
                      <b-btn
                        variant="warning"
                        @click="deleteField(searchIndex, tab)"
                      >
                        X
                      </b-btn>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
              </template>
              <b-col cols="6">
                <b-form-group label="View">
                  <b-form-radio-group
                    v-model="tab.parentTable"
                    :options="parentTables"
                    button-variant="outline-primary"
                    size="md"
                    name="radio-btn-outline"
                    buttons
                    @change="chooseTable(tab)"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Adddress & Customs Status">
                  <b-form-checkbox-group v-model="tab.searchForm.subStatusArr">
                    <b-checkbox value="-1">Address problem</b-checkbox>
                    <b-checkbox value="20">clearance started</b-checkbox>
                    <b-checkbox value="21">2 day stale</b-checkbox>
                    <b-checkbox value="22"> Pending</b-checkbox>
                    <b-checkbox value="23">Failed</b-checkbox>
                    <b-checkbox value="30">Waiting for collection</b-checkbox>
                    <b-checkbox value="56">Refunded</b-checkbox>
                  </b-form-checkbox-group>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Region">
                  <b-form-radio-group
                    id="btn-radios-2"
                    v-model="tab.searchForm.region"
                    :options="regions"
                    button-variant="outline-primary"
                    size="md"
                    name="radio-btn-outline"
                    buttons
                    @change="chooseRegion"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Status">
                  <b-form-checkbox-group v-model="tab.searchForm.statusArr">
                    <b-checkbox value="-1"> ID unmatched</b-checkbox>
                    <b-checkbox value="0"> Processing</b-checkbox>
                    <b-checkbox value="1"> Errored</b-checkbox>
                    <b-checkbox value="2"> Bought</b-checkbox>
                    <b-checkbox value="3"> All Bought</b-checkbox>
                    <b-checkbox value="4"> Arrived</b-checkbox>
                    <b-checkbox value="5"> Shipped</b-checkbox>
                    <b-checkbox value="6"> Cancelling</b-checkbox>
                    <b-checkbox value="7"> Cancelled</b-checkbox>
                  </b-form-checkbox-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-btn type="submit"> Submit</b-btn>
                <b-btn type="button" @click="onSearchNewTab(tab)">
                  Submit(New tab)
                </b-btn>
              </b-col>
            </b-row>
          </b-form>
          <template #footer> ⩲ {{ total }}</template>
        </b-card>

        <b-row class="my-3">
          <b-col cols="2">
            <b-select
              v-model="perPage"
              :options="perPages"
              @change="onSearch(tab)"
            />
          </b-col>
          <b-col>
            <b-pagination
              v-model="currentPage"
              align="center"
              class="my-3"
              :limit="10"
              :total-rows="100"
              :per-page="10"
            />
          </b-col>
        </b-row>

        <span v-for="(idx, page) in pages" :key="page">
          <b-btn @click="getPage(idx)">{{ page + 1 }}</b-btn>
        </span>
        <b-btn @click="getNextPage">Next Page</b-btn>

        <b-card
          v-for="(fee, feeIndex) of tab.parents"
          :key="fee.idx"
          class="my-1"
        >
          <b-card-header>
            <b-row>
              <b-col>
                <a
                  target="_blank"
                  :href="`http://www.uniauc.com/admin/${$route.params.parentTable}_list.php?receive_nation=&status=&act=search&searchKey=idx%3B${$route.params.parentTable}&searchVal=${fee.idx}&searchType=perfect`"
                  ><i class="fa fa-link mx-2"
                /></a>

                <b-modal
                  v-if="openModalIdx === fee.idx"
                  id="parentEditModal"
                  hide-footer
                  title="Parent Edit"
                  size="lg"
                  @ok="onParentEdit(fee, tab)"
                >
                  <b-form @submit.prevent="onParentEdit(fee, tab)">
                    <b-row>
                      <b-col
                        v-for="field of editFormParent"
                        :key="field.key"
                        xs="12"
                        sm="6"
                        description=""
                      >
                        <label :for="field.key">
                          {{
                            (field.text ? field.text : field.key).toUpperCase()
                          }}
                        </label>

                        <b-form-input
                          :id="field.key"
                          v-model.trim="field.value"
                          placeholder=""
                          @change="
                            pushParentChange(fee, field.key, $event, tab)
                          "
                        />
                      </b-col>
                    </b-row>
                    <b-row class="mt-3">
                      <b-col>
                        <b-btn-group>
                          <b-btn variant="primary" type="submit"> OK</b-btn>
                          <b-btn
                            variant="warning"
                            @click="$bvModal.hide('parentEditModal')"
                          >
                            CANCEL
                          </b-btn>
                          <b-btn variant="danger" @click="clearParentFields()">
                            CLEAR
                          </b-btn>
                        </b-btn-group>
                      </b-col>
                    </b-row>
                  </b-form>
                </b-modal>

                <font-awesome-icon
                  v-if="tab.parentTable === 'logis'"
                  icon="edit"
                  @click="parentEdit(fee)"
                />
                {{ fee.region }}
                [<a
                  style="cursor: pointer"
                  :id="fee.idx"
                  @click="getChangeLog('logis', fee.idx)"
                  >{{ fee.idx }}</a
                >]
                <b-badge
                  v-if="$route.params.parentTable === 'fees' && fee.paid === -1"
                  variant="danger"
                >
                  Unpaid
                </b-badge>
                <b-badge
                  v-else-if="
                    $route.params.parentTable === 'logis' && fee.paid === 2
                  "
                  variant="success"
                >
                  Paid </b-badge
                >&nbsp;
                <b-badge v-if="fee.userid === 'glomarketb'" variant="danger">
                  {{ fee.userid }}
                </b-badge>
                <span v-else @click="searchUser">{{ fee.userid }}</span>
                {{ fee.feecode }}
                {{ fee.logicode }}
                <a target="_blank" :href="getCarrierLink(fee)">
                  <b-badge v-if="fee.carrier" variant="info"
                    >{{ fee.carrier.toUpperCase() }}
                  </b-badge>
                  <b-badge v-if="fee.carrier" variant="info">
                    {{ fee.weight }}
                  </b-badge>
                </a>
                {{ fee.tnum }}
                <b-btn-group>
                  <b-btn @click="printBL(fee)">print</b-btn>
                  <b-btn variant="info" @click="printInvoice(fee)"
                    >Invoice
                  </b-btn>
                  <b-btn
                    v-if="fee.countryId === 3"
                    @click="printAwb(fee, 'yye')"
                  >
                    YY Print
                  </b-btn>
                </b-btn-group>

                <span v-if="fee.status === -1">
                  ! Created:
                  <b>{{ new Date(fee.writeday).toLocaleString() }}</b>
                </span>
                <span v-if="fee.status === 0">
                  🎬 Created:
                  <b>{{ new Date(fee.writeday).toLocaleString() }}</b>
                </span>
                <span v-if="fee.status === 1">
                  ❗ Created:
                  <b>{{ new Date(fee.writeday).toLocaleString() }}</b>
                  <!--              Errored: {{ fee.erroredAt }}-->
                </span>
                <span v-if="fee.status === 2">
                  🚚 Bought: {{ fee.procuredAt }}
                </span>
                <span v-if="fee.status === 3">
                  🚚 Bought: {{ fee.procuredAt }}
                </span>
                <span v-if="fee.status === 4">
                  🏤 Arrived: {{ fee.arriveday }}
                </span>
                <span v-if="fee.status === 5">
                  <span class="text-success">⬤</span> Shipped: {{ fee.shipday }}
                </span>
                <span v-if="fee.defer === 2">
                  Deferred: {{ fee.deferday }}
                </span>
                <span v-if="fee.defer === 5"> Scanned: {{ fee.scanday }} </span>
                <b-btn-group>
                  <b-btn
                    v-if="$route.params.parentTable === 'logis'"
                    @click="copy(fee.idx, 2)"
                  >
                    Copy
                  </b-btn>
                  <b-btn
                    variant="warning"
                    v-if="$route.params.parentTable === 'logis'"
                    @click="extract"
                  >
                    divide
                  </b-btn>
                </b-btn-group>
              </b-col>
              <h5 v-if="fee.currentState">
                <b-badge variant="info">
                  {{ fee.currentState }}
                </b-badge>
              </h5>
              <b-col
                v-if="
                  fee.status < 5 && fee.feecode && tab.parentTable === 'fees'
                "
                v-show="!fee.currentState"
                class="text-right"
              >
                <b-btn
                  v-if="fee.orders[0].paid === -1"
                  variant="success"
                  @click="
                    submit('confirm', { feecode: fee.feecode }, fee, fee.orders)
                  "
                >
                  Confirm
                </b-btn>
                <b-btn
                  type="button"
                  variant="secondary"
                  @click="
                    submit(
                      'partialRetry',
                      { feecode: fee.feecode },
                      fee,
                      fee.orders,
                      fee
                    )
                  "
                >
                  Partial Retry
                </b-btn>
                <b-btn
                  type="button"
                  variant="info"
                  @click="
                    submit(
                      'retry',
                      {
                        feecode: fee.feecode,
                        idxs: fee.orders.map((order) => order.idx),
                      },
                      fee,
                      fee.orders,
                      fee
                    )
                  "
                >
                  Retry All
                </b-btn>
                <b-btn
                  type="button"
                  variant="warning"
                  @click="
                    submit(
                      'cancel',
                      {
                        feecode: fee.feecode,
                        idxs: fee.orders.map((order) => order.idx),
                      },
                      fee,
                      fee.orders
                    )
                  "
                >
                  Cancel All
                </b-btn>
              </b-col>
              <b-col v-if="$route.query.status === '3'" class="text-right">
                <b-btn
                  type="button"
                  variant="success"
                  @click="
                    submit(
                      'shipped',
                      {
                        feecode: fee.feecode,
                        idxs: fee.orders.map((order) => order.idx),
                      },
                      fee,
                      fee.orders
                    )
                  "
                >
                  All shipped
                </b-btn>
                <b-btn
                  type="button"
                  variant="danger"
                  @click="
                    submit(
                      'canceled',
                      {
                        feecode: fee.feecode,
                        idxs: fee.orders.map((order) => order.idx),
                      },
                      fee,
                      fee.orders
                    )
                  "
                >
                  All canceled
                </b-btn>
              </b-col>
              <b-col cols="1">
                <span v-if="fee.defer !== 2 && fee.defer < 5 && fee.status < 5">
                  <b-form @submit.prevent="onWeightSubmit(fee)">
                    <b-input
                      v-model="fee.weight"
                      placeholder="Weight"
                    ></b-input>
                  </b-form>
                </span>
              </b-col>
              <b-col cols="2">
                <b-radio-group
                  v-model.trim="fee.region"
                  placeholder=""
                  button-variant="outline-primary"
                  @click.native="onClickRegion(fee, $event)"
                  buttons
                >
                  <b-radio value="US">US</b-radio>
                  <b-radio value="CN">CN</b-radio>
                </b-radio-group>
              </b-col>
            </b-row>
            <b-row>
              <!--        <b-btn v-if="$route.params.parentTable==='logis'">cancel</b-btn>-->
              <!--        <b-button-group>-->
              <!--          <b-dropdown right text="Cancel">-->
              <!--            <b-dropdown-item @click="cancel('customerRequest')">Customer requested</b-dropdown-item>-->
              <!--            <b-dropdown-item @click="cancel('notArrived')">Item not arrived</b-dropdown-item>-->
              <!--          </b-dropdown>-->
              <!--        </b-button-group>-->
              <!--        <b-btn v-if="$route.params.parentTable==='logis'" @click="$bvModal.show('refundModal')">Partial Refund</b-btn>-->

              <div>{{ fee.desc }}</div>
              <div
                class="mt-3"
                :class="{
                  'bg-success': fee.currentState === 'changed',
                }"
              >
                <span v-if="fee.status === -1 && fee.countryCode === 'KR'">
                  <b-btn size="sm" variant="success" @click="modifyId(fee)">
                    Modify ID
                  </b-btn>
                  <b-btn
                    size="sm"
                    variant="danger"
                    @click="updateLogis(fee, { defer: 14, status: -1 })"
                  >
                    Unreachable
                  </b-btn>
                </span>
                <span>
                  {{ fee.savemsg }}
                </span>
              </div>
              <div>
                {{ fee.name }}
                <b-badge variant="warning">{{ fee.hand }}</b-badge>
                {{ fee.idno }} {{ fee.adr1 }} {{ fee.adr2 }} {{ fee.city }}
                {{ fee.province }} {{ fee.zip }} {{ fee.countryCode }}
                {{ fee.email }}
              </div>
            </b-row>
          </b-card-header>
          <b-card-body class="mp-0">
            <b-checkbox-group v-model="checkedItems">
              <!--          You need v-if because when you click empty checkbox before the loading, [true] along with the idx value will be posted when submit the form.-->
              <b-row
                v-for="(order, orderIndex) of fee.orders"
                v-if="order.idx > 0"
                :key="order.idx"
                class="border-bottom mt-1"
              >
                <b-col cols="1">
                  <b-checkbox :value="order.idx" />
                  <a
                    target="_blank"
                    :href="
                      'http://www.uniauc.com/admin/logis_list.php?receive_nation=&status=&act=search&searchKey=idx%3Borders&searchVal=' +
                      order.idx +
                      '&searchType=perfect'
                    "
                  >
                    <b-img rounded width="80" height="80" :src="order.img" />
                  </a>
                </b-col>
                <b-col cols="7" :class="'bg-' + stateColors[order.status]">
                  <div />
                  <b-modal
                    v-if="openModalIdx === order.idx"
                    id="editModal"
                    hide-footer
                    title="Edit"
                    size="lg"
                    @ok="onEdit(fee, tab)"
                  >
                    <b-form @submit.prevent="onEdit(fee, tab)">
                      <b-row>
                        <b-col
                          v-for="{ name, key: fieldKey } of editGroup"
                          :key="fieldKey"
                          xs="12"
                          sm="6"
                          description=""
                        >
                          <b-modal
                            v-if="openBulkModalKey === fieldKey"
                            id="bulkEditModal"
                            @ok="onEdit(fee, tab)"
                          >
                            <b-form>
                              <b-form-group
                                v-for="(order, id) of tab.parents.find(
                                  (parent) => parent.idx === fee.idx
                                ).orders"
                                :key="id"
                                description=""
                              >
                                <label :for="order.idx + ''">
                                  [{{ order.idx }}] {{ fieldKey }}
                                </label>
                                <b-form-input
                                  :id="order.idx + ''"
                                  v-model.trim="order[fieldKey]"
                                  placeholder=""
                                  @change="
                                    pushChangeBulk(
                                      fieldKey,
                                      $event,
                                      tab.parents.find(
                                        (parent) => parent.idx === fee.idx
                                      ).orders
                                    )
                                  "
                                />
                              </b-form-group>
                            </b-form>
                          </b-modal>

                          <label :for="fieldKey">
                            {{ (name ? name : fieldKey).toUpperCase() }}
                            <b-badge @click="bulkEdit(fieldKey, fee, tab)"
                              >More
                            </b-badge>
                            <span v-if="fieldKey === 'costTotal'">
                              (Discount
                              {{
                                (order.priceSum - order.costTotal).toFixed(2)
                              }})
                            </span>
                          </label>
                          <b-form-input
                            :id="fieldKey"
                            v-model.trim="order[fieldKey]"
                            placeholder=""
                            @change="pushChange(order, fieldKey, $event)"
                          />
                        </b-col>
                      </b-row>
                      <b-row class="mt-3">
                        <b-col>
                          <b-input v-model="splitQty" />
                        </b-col>
                        <b-col>
                          <b-btn @click="split(order.idx, tab)"> Split</b-btn>
                        </b-col>
                      </b-row>
                      <b-row class="mt-3">
                        <b-col>
                          <b-input v-model="newQty" />
                        </b-col>
                        <b-col>
                          <b-btn
                            @click="
                              changeQty(
                                tab.parents
                                  .find((parent) => parent.idx === fee.idx)
                                  .orders.find(
                                    (child) => child.idx === order.idx
                                  )
                              )
                            "
                          >
                            Change QTY
                          </b-btn>
                        </b-col>
                      </b-row>

                      <b-row class="mt-3">
                        <b-col>
                          <b-btn type="submit"> OK</b-btn>
                        </b-col>
                      </b-row>
                    </b-form>
                  </b-modal>

                  <font-awesome-icon icon="edit" @click="edit(order)" />

                  <a
                    class="mx-1"
                    style="cursor: pointer"
                    :id="order.idx"
                    @click="getChangeLog('orders', order.idx)"
                  >
                    <i class="fa fa-sticky-note"></i
                  ></a>
                  [{{ order.idx }}]

                  <b-badge
                    class="mr-1"
                    :variant="order.cnt > 1 ? 'warning' : 'secondary'"
                  >
                    {{ order.cnt }}
                  </b-badge>
                  <b-badge v-if="order.paid < 0" variant="danger" class="mr-2">
                    unpaid
                  </b-badge>
                  <a target="_blank" :href="order.url">
                    <span
                      :style="{
                        'text-decoration': [2, 3].includes(order.status)
                          ? 'text-light'
                          : '',
                        'text-decoration': [6, 7].includes(order.status)
                          ? 'line-through'
                          : '',
                      }"
                      >{{ order.title }} {{ order.color }}
                      {{ order.size }}</span
                    ></a
                  >
                  {{ order.category }} {{ order.hscode }}<br />
                  <a
                    v-if="order.titleOrg.startsWith('http')"
                    target="_blank"
                    :href="order.titleOrg"
                  >
                    <span
                      :style="{
                        'text-decoration': [2, 3].includes(order.status)
                          ? 'text-light'
                          : '',
                        'text-decoration': [6, 7].includes(order.status)
                          ? 'line-through'
                          : '',
                      }"
                      >{{ order.titleOrg }}</span
                    ></a
                  >
                  {{ order.deliverydate_info }} {{ order.msgs }}
                  {{ order.trade_id }} {{ order.idcode }}
                  {{ order.marketId }}
                  <a
                    :href="
                      'https://www.amazon.com/gp/product/' + order.itemCode
                    "
                  >
                    <b-badge variant="danger">{{ order.itemCode }}</b-badge>
                  </a>
                  <div
                    :style="{
                      color: order.status !== 1 ? 'grey' : '',
                    }"
                  >
                    {{ order.error }}
                  </div>
                  <div v-if="[2, 3].includes(order.status)">
                    Arrives in
                    <input
                      v-model="order.arrivesIn"
                      class="ml-2"
                      style="width: 50px"
                    />
                  </div>
                  <div>
                    <b-btn
                      size="md"
                      variant="danger"
                      @click="ban(order)"
                      v-if="fee.region === 'CN'"
                      >drop item
                    </b-btn>
                    <b-btn
                      size="md"
                      variant="danger"
                      v-if="fee.region === 'CN'"
                      @click="banSeller(order)"
                      >drop seller
                    </b-btn>
                    <b-btn
                      variant="warning"
                      @click="
                        submit(
                          'cancel',
                          { idxs: [order.idx] },
                          order,
                          fee.orders
                        )
                      "
                    >
                      Cancel
                    </b-btn>
                    <b-btn
                      size="sm"
                      variant="success"
                      @click="unban(order)"
                      v-if="fee.region === 'CN'"
                      >Undrop item
                    </b-btn>
                    <b-btn
                      size="sm"
                      variant="success"
                      v-if="fee.region === 'CN'"
                      @click="unbanSeller(order)"
                      >Undrop seller
                    </b-btn>
                  </div>
                </b-col>
                <b-col>
                  {{ order.price }} ({{ getCurrencyUnit(order.targetRegion)
                  }}{{ order.charge }}+{{ order.proratedShipFee }}-{{
                    order.proratedShipCost
                      ? order.proratedShipCost.toFixed(2)
                      : null
                  }}={{
                    (
                      order.charge +
                      order.proratedShipFee -
                      order.proratedShipCost
                    ).toFixed(2)
                  }}/{{ order.fxRate ? order.fxRate.toFixed(2) : null }} =
                  {{
                    (
                      (order.charge +
                        order.proratedShipFee -
                        order.proratedShipCost) /
                      order.fxRate
                    ).toFixed(2)
                  }})
                  <!--                                    {{order.fxRate}}-->
                  <div v-if="order.cnt > 1">
                    {{ (order.price * order.cnt).toFixed(2) }} ({{
                      getCurrencyUnit(order.targetRegion)
                    }}{{ (order.charge * order.cnt).toFixed(2) }})
                  </div>
                  <div>
                    <b-input
                      v-model="order.estWeight"
                      @keyup="calcShipCost(order)"
                      @change="pushChange(order, 'estWeight', $event)"
                    />
                  </div>
                </b-col>
                <b-col
                  :class="{
                    'bg-success': ['bought', 'changed'].includes(
                      order.currentState
                    ),
                  }"
                >
                  <b-row>
                    <b-col v-if="order.currentState">
                      <h1>
                        <b-badge variant="info">
                          {{ order.currentState }}
                        </b-badge>
                      </h1>
                    </b-col>
                  </b-row>

                  <b-row class="mb-2" v-show="[2, 3, 4].includes(order.status)">
                    <b-col xs="6" description="">
                      <b-form-input
                        v-model.trim="order.hscode"
                        @change="changeHScode(order)"
                      />
                    </b-col>
                    <b-col xs="6" description="">
                      <b-form-input
                        v-model.trim="order.arrivesAt"
                        @change="changeArrivesAt(order)"
                      />
                    </b-col>
                  </b-row>
                  <b-row
                    v-if="!order.currentState && [0, 1].includes(order.status)"
                  >
                    <b-col>
                      (Discount
                      {{ (order.priceSum - order.costTotal).toFixed(2) }})
                      <b-row class="mb-2">
                        <b-col
                          v-for="{ name, key: fieldKey } of editGroupIssues"
                          :key="fieldKey"
                          :placeholder="name"
                          xs="12"
                          sm="6"
                          description=""
                        >
                          <b-form-input
                            :id="fieldKey"
                            v-model="order[fieldKey]"
                            :placeholder="name"
                            @change="pushChange(order, fieldKey, $event)"
                          />
                          <!--                          <b-form-input-->
                          <!--                            :id="fieldKey"-->
                          <!--                            v-model.trim="order[fieldKey]"-->
                          <!--                            :placeholder="name"-->
                          <!--                            @change="pushChange(order, fieldKey, $event)"-->
                          <!--                          />-->
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-btn-group v-if="fee.feecode">
                            <!-- if you retry minimum order or cannot be shipped order, auto buyer will infinitely repeat the buying process -->
                            <b-btn
                              v-show="
                                !order.error.match(
                                  /minimum order|cannot be shipped|not enough/
                                )
                              "
                              variant="info"
                              @click="
                                submit(
                                  'retry',
                                  { idxs: [order.idx] },
                                  order,
                                  fee.orders,
                                  fee
                                )
                              "
                            >
                              Force
                            </b-btn>
                            <b-btn
                              v-if="order.paid !== -1"
                              variant="success"
                              @click="
                                submit(
                                  'bought',
                                  { idxs: [order.idx] },
                                  order,
                                  fee.orders,
                                  fee
                                )
                              "
                            >
                              Bought
                            </b-btn>
                            <!--                <b-btn v-if="order.userid === 'rakuten'" :pressed.sync="order.currentState === 'canceled'"-->
                            <!--                       variant="danger"-->
                            <!--                       @click="submit('canceled',{idxs:[order.idx]},order,fee.orders)">-->
                            <!--                  delete-->
                            <!--                </b-btn>-->
                            <b-btn
                              variant="warning"
                              @click="
                                submit(
                                  'cancel',
                                  { idxs: [order.idx] },
                                  order,
                                  fee.orders
                                )
                              "
                            >
                              Cancel
                            </b-btn>
                          </b-btn-group>
                          <b-btn-group
                            v-if="$route.query.status === '3'"
                            v-show="!order.currentState"
                          >
                            <b-btn
                              variant="info"
                              @click="
                                submit(
                                  'retry',
                                  { idxs: [order.idx] },
                                  order,
                                  fee.orders
                                )
                              "
                            >
                              Retry
                            </b-btn>
                            <b-btn
                              variant="success"
                              @click="
                                submit(
                                  'shipped',
                                  { idxs: [order.idx] },
                                  order,
                                  fee.orders
                                )
                              "
                            >
                              Shipped
                            </b-btn>
                            <b-btn
                              variant="warning"
                              @click="
                                submit(
                                  'cancel',
                                  { idxs: [order.idx] },
                                  order,
                                  fee.orders
                                )
                              "
                            >
                              Cancel
                            </b-btn>
                            <b-btn
                              variant="danger"
                              @click="
                                submit(
                                  'canceled',
                                  { idxs: [order.idx] },
                                  order,
                                  fee.orders
                                )
                              "
                            >
                              Canceled
                            </b-btn>
                          </b-btn-group>
                          <!--        Current:-->
                          <!--        <b-btn>{{order.currentState}}</b-btn>-->
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-checkbox-group>
          </b-card-body>
        </b-card>

        <b-pagination
          v-model="currentPage"
          align="center"
          class="my-3"
          :limit="10"
          :total-rows="100"
          :per-page="10"
        />
        <b-btn class="mb-5" @click="showSummary = !showSummary">Summary</b-btn>

        <b-table
          v-if="showSummary"
          :items="tab.parents"
          :fields="fields"
          responsive
        >
          <template #cell(actions)="row">
            <b-button size="sm" class="mr-1" @click="goto(row)">
              Detail
            </b-button>
          </template>
          <template #cell(adr)="row">
            {{ row.item.adr1 }} {{ row.item.adr2 }}
          </template>
        </b-table>
        <!--    <b-modal id="refundModal" @ok="refund">-->
        <!--      <b-input v-model="refundAmount"></b-input>-->
        <!--    </b-modal>-->
        <b-modal
          class="modal-fullscreen"
          id="changeLogModal"
          title="Change Log"
          size="lg"
          responsive
        >
          <b-table :items="changeLogs" :fields="changeLogFields" responsive>
            <template #cell(createdAt)="row">
              <b-badge size="sm" class="mr-1">
                {{ new Date(row.item.createdAt).toLocaleString() }}
              </b-badge>
            </template>
          </b-table>
        </b-modal>
      </b-tab>
    </b-tabs>
  </b-container>
</template>

<script>
const axios = require("axios");

export default {
  name: "OrderList",
  components: {},
  data() {
    return {
      pages: [],
      showSummary: false,
      perPage: 200,
      perPages: [20, 50, 100, 200, 300].map((perPage) => {
        return {
          text: "Show " + perPage,
          value: perPage,
        };
      }),
      rates: [],
      CNYUSD: 0,
      openBulkModalKey: "",
      openModalIdx: 0,
      currentTab: 0,
      changeLogs: [],
      changeLogFields: [
        {
          key: "createdAt",
          label: "Time",
          stickyColumn: true,
          isRowHeader: true,
        },
      ],
      basicFields: [
        { text: "User ID", value: "userid;logis" },
        { text: "User ID", value: "userid;fees" },
        { text: "User ID", value: "userid;orders" },
        { text: "Package No", value: "idx;logis" },
        {
          text: "Order No",
          value: "idx;orders",
        },
        { text: "Recipient Name", value: "name;logis" },
      ],
      searchFields: [],
      parentTables: [
        { value: "fees", text: "domestic" },
        { value: "logis", text: "shipping" },
        { value: "orders", text: "orders" },
      ],
      regions: ["US", "CN", "DE", "JP", ""],
      checkedItems: [],
      newQty: 0,
      splitQty: 0,
      splitIdx: 0,
      stateColors: {
        1: "info",
        2: "secondary",
        3: "secondary",
        4: "",
        5: "success",
      },
      currentBulkKey: "",
      currentOrder: {},
      currentId: 0,
      form: {
        // url: "",
        // cnt: "",
        price: "",
        // charge: ""
      },
      //TODO: Later change the DB and make declaredUnitPrirce,marketTotalPrice,discountedTotalPrice
      //the discount will be marketTotalPrice-declaredTotalPrice
      editFormParent: [
        { key: "name", text: "Name", value: "" },
        { key: "hand", text: "Phone", value: "" },
        { key: "adr1", text: "address 1", value: "" },
        { key: "adr2", text: "address 2", value: "" },
        { key: "city", text: "city", value: "" },
        { key: "province", text: "province", value: "" },
        { key: "zip", text: "zip code", value: "" },
        { key: "countryCode", text: "country code", value: "" },
        { key: "idno", text: "ID Number", value: "" },
        { key: "tnum", text: "HBL No", value: "" },
        { key: "email", text: "email", value: "" },
        { key: "managermemo", text: "Manager memo", value: "" },
        { key: "desc", text: "Packing memo", value: "" },
        { key: "memo", text: "Delivery memo", value: "" },
        { key: "errmemo", text: "Issue", value: "" },
      ],
      editGroupParent: [
        { name: "Name", key: "name" },
        { name: "Phone", key: "hand" },
        { name: "address 1", key: "adr1" },
        { name: "address 2", key: "adr2" },
        { name: "city", key: "city" },
        { name: "province", key: "province" },
        { name: "zip code", key: "zip" },
        { name: "country code", key: "countryCode" },
        { name: "ID Number", key: "idno" },
        { name: "HBL No", key: "tnum" },
        { name: "email", key: "email" },
        { name: "Defer status", key: "defer" },
        { name: "Internal memo", key: "savemsg" },
        { name: "Packing memo", key: "desc" },
      ],
      editGroupIssues: [
        { name: "Discounted Total Price", key: "costTotal" },
        { name: "Order No.", key: "trade_id" },
      ],
      editGroupCN: [{ name: "HS Code", key: "hscode" }],
      editGroup: [
        { name: "Declared unit price", key: "price" },
        { name: "Qty", key: "cnt" },
        { name: "Market Total Price", key: "declarePrice" },
        { name: "Estimated Weight", key: "estWeight" },
        { name: "Discounted Total Price", key: "costTotal" },
        { name: "Unit Revenue", key: "charge" },
        { name: "Market ID", key: "marketId" },
        { name: "Order No.", key: "trade_id" },
        { name: "URL", key: "url" },
        { name: "Image URL", key: "img" },
        { name: "Title", key: "title" },
        // { name: "bought Date", key: "procuredAt" },
        { name: "Alternative title", key: "titleOrg" },
        { name: "category", key: "category" },
        { name: "hs code", key: "hscode" },
        { name: "clone No.", key: "cloneSeq" },
        { name: "Delivery Info", key: "deliverydate_info" },
        { name: "Arrives At", key: "arrivesAt" },
        { key: "error" },
        { key: "status" },
        // { key: "logicode" },
        // { key: "feecode" },
        { name: "Short Tracking", key: "stnum" },
        { name: "Tracking", key: "tnum" },
      ],
      tabs: [
        {
          title: "Basic",
          parentTable: "logis",
          searchForm: {
            statusArr: [],
            subStatusArr: [],
            region: "",
            searchEntities: [
              {
                searchValue: "",
                searchField: "userid;logis",
                searchType: "=",
              },
              {
                searchValue: "",
                searchField: "name;logis",
                searchType: "=",
              },
              {
                searchValue: "",
                searchField: "title;orders",
                searchType: "like",
              },
            ],
          },
          parents: [{}],
        },
        {
          title: "Adr issues JP",
          parentTable: "logis",
          searchForm: {
            statusArr: [-1],
            // subStatusArr: [-1],
            region: "",
            searchEntities: [
              {
                searchValue: "JP",
                searchField: "countryCode;logis",
                searchType: "=",
              },
            ],
          },
          parents: [{}],
        },
        {
          title: "Stock",
          parentTable: "logis",
          searchForm: {
            statusArr: [],
            isMarket: false,
            region: "",
            searchEntities: [
              {
                searchValue: "4",
                searchField: "status;orders",
                searchType: "=",
              },
              {
                searchValue: "0",
                searchField: "paid;orders",
                searchType: ">=",
              },
              {
                searchValue: "1",
                searchField: "internal;orders",
                searchType: "=",
              },
            ],
          },
          parents: [
            {
              orders: [
                {
                  arrivesIn: 0,
                },
              ],
            },
          ],
        },
        {
          title: "Issues",
          parentTable: "fees",
          searchForm: {
            statusArr: [],
            isMarket: false,
            region: "",
            searchEntities: [
              {
                searchValue: "1",
                searchField: "status;orders",
                searchType: "=",
              },
              {
                searchValue: "-2",
                searchField: "paid;fees",
                searchType: "not",
              },
            ],
          },
          parents: [
            {
              orders: [
                {
                  arrivesIn: 0,
                },
              ],
            },
          ],
        },
        {
          title: "CN orders",
          parentTable: "orders",
          searchForm: {
            statusArr: ["0"],
            isMarket: false,
            region: "CN",
            searchEntities: [
              {
                searchValue: "3",
                searchField: "selfbuy;orders",
                searchType: "=",
              },
            ],
          },
          parents: [
            {
              orders: [
                {
                  arrivesIn: 0,
                },
              ],
            },
          ],
        },
        {
          title: "ID match",
          parentTable: "logis",
          searchForm: {
            statusArr: ["-1"],
            region: "",
            searchEntities: [
              {
                searchValue: "KR",
                searchField: "countryCode;logis",
                searchType: "=",
              },
              {
                searchField: "status;orders",
                searchValue: "7",
                searchType: "not",
              },
            ],
          },
          parents: [
            {
              orders: [
                {
                  arrivesIn: 0,
                },
              ],
            },
          ],
        },
        {
          title: "Customs ",
          parentTable: "logis",
          searchForm: {
            statusArr: ["5"],
            subStatusArr: [21, 22, 23],
            searchEntities: [
              {
                searchField: "selfbuy;logis",
                searchValue: "3",
                searchType: "=",
              },
            ],
          },
          parents: [
            {
              orders: [
                {
                  arrivesIn: 0,
                },
              ],
            },
          ],
        },
        {
          title: "Return",
          parentTable: "logis",
          searchForm: {
            statusArr: [],
            subStatusArr: [56, 57],
            searchEntities: [
              {
                searchField: "selfbuy;logis",
                searchValue: "3",
                searchType: "=",
              },
              {
                searchField: "status;logis",
                searchValue: "7",
                searchType: "not",
              },
            ],
          },
          parents: [
            {
              orders: [
                {
                  arrivesIn: 0,
                },
              ],
            },
          ],
        },
        {
          title: "Old",
          parentTable: "logis",
          searchForm: {
            statusArr: [],
            listType: "old",
            isMarket: true,
            searchEntities: [
              {
                searchValue: new Date().toISOString(),
                searchField: "arrivesAt;orders",
                searchType: "<=",
              },
              {
                searchValue: "2",
                searchField: "status;orders",
                searchType: ">=",
              },
              {
                searchValue: "3",
                searchField: "status;orders",
                searchType: "<=",
              },
            ],
          },
          parents: [{}],
        },
      ],
      fields: [
        "actions",
        "idx",
        "name",
        "hand",
        "adr",
        "weight",
        "carrier",
        "writeday",
      ],
      page: 1,
      filter: "",
      historyFilter: "",
      currentPage: 1,
      current: [],
      items: [],
      currentFields: {
        img: {},
        title: {
          sortable: true,
        },
        size: {},
        qty: {
          sortable: true,
        },
      },
      total: 0,
      stockData: "",
    };
  },
  watch: {
    currentPage(currentPage) {
      console.log(currentPage);
      this.$router.push({
        query: { ...this.$route.query, currentPage, perPage: this.perPage },
      });
      this.onSearch(this.tabs[this.currentTab]);
    },
  },
  async created() {
    if (this.$route.query.searchForm) {
      const tab = {
        title: "Search",
        parentTable: this.$route.params.parentTable,
        searchForm: JSON.parse(this.$route.query.searchForm),
      };
      this.onSearchNewTab(tab);
    }
    this.init();
    setInterval(() => {
      this.$warn("The window will be refreshed after 60 seconds.");
      setTimeout(() => {
        this.onSearch(this.tabs[this.currentTab]);
      }, 60 * 1000);
    }, 1 * 3600 * 1000);
  },
  mounted() {},

  methods: {
    clearParentFields(modalId) {
      this.editFormParent.forEach((field) => {
        field.value = "";
      });
    },
    getPage(lastIdx) {
      const tab = this.tabs[this.currentTab];
      this.$router.push({
        query: { ...this.$route.query, lastIdx, perPage: this.perPage },
      });
      console.log(lastIdx);
      this.init();
    },
    async getNextPage() {
      const tab = this.tabs[this.currentTab];
      const lastIdx = tab.parents ? tab.parents.pop().idx : "";
      const searched = await this.onSearch(tab, lastIdx);
      if (searched) this.pages.push(lastIdx);
    },
    searchUser(userid) {
      this.tabs[this.currentTab].searchForm.searchEntities.push({
        searchField: "userid;logis",
        searchValue: userid,
        searchType: "=",
      });
      this.onSearch(this.tabs[this.currentTab]);
    },
    async ban(order) {
      if (window.confirm("Proceed?")) {
        const { error } = await axios.post("/ban", { uid: order.itemCode });
        if (error) this.$warn(error);
        else this.$warn("banned");
      }
    },
    async unban(order) {
      if (window.confirm("Proceed?")) {
        const { error } = await axios.post("/unban", { uid: order.itemCode });
        if (error) this.$warn(error);
        else this.$warn("unbanned");
      }
    },
    async banSeller(order) {
      if (window.confirm("Proceed?")) {
        const { error } = await axios.post("/ban-seller", {
          uid: order.itemCode,
        });
        if (error) this.$warn(error);
        else this.$warn("banned");
      }
    },
    async unbanSeller(order) {
      if (window.confirm("Proceed?")) {
        const { error } = await axios.post("/unban-seller", {
          uid: order.itemCode,
        });
        if (error) this.$warn(error);
        else this.$warn("unbanned");
      }
    },
    async onWeightSubmit(logi) {
      await this.updateLogis(logi, { weight: logi.weight });
      await this.printBL(logi);
    },
    printInvoice(fee) {
      window.open(`https://api.resell.glocing.com/invoice/${fee.logicode}`);
    },
    printBL(fee) {
      if (fee.feecode)
        window.open("https://api.uniauc.com/create-sgw-awb/" + fee.feecode);
      if (fee.logicode)
        window.open("https://api.uniauc.com/create-sgw-awb/" + fee.logicode);
    },
    pushChangeObj(obj, order) {
      Object.entries(obj).forEach(([key, val]) => {
        this.pushChange(order, key, val);
      });
    },
    onClickRegion(fee, evt) {
      const region = evt.target.value;
      if (!region) return;
      console.log(region);
      let currencyRate;
      if (region === "CN") {
        currencyRate = 1 / this.CNYUSD;
      } else if (region === "US") {
        currencyRate = 1;
      }
      fee.orders.forEach((order) => {
        order.region = region;
        order.price = ((order.declarePrice * currencyRate) / order.cnt).toFixed(
          2
        );

        order.costTotal = (order.costTotal * (1 / currencyRate)).toFixed(2) * 1;

        if (region === "CN")
          order.trade_id = `Taobao ${new Date().toLocaleDateString()}`;

        const changeObj = {
          region: region,
          price: order.price,
          trade_id: order.trade_id,
        };
        this.pushChangeObj(changeObj, order);
      });

      this.$forceUpdate();
    },
    async getChangeLog(table, idx) {
      const { data: changeLogs } = await axios.get(`/get-change-log`, {
        params: {
          id: idx,
          tableName: table,
        },
      });
      for (const changeLog of changeLogs) {
        const fields = JSON.parse(changeLog.data);
        for (const fieldKey in fields) {
          this.changeLogFields.push({
            key: fieldKey,
            label: fieldKey,
          });
          changeLog[fieldKey] = fields[fieldKey];
        }
      }
      this.changeLogs = changeLogs;

      this.$bvModal.show("changeLogModal");
    },
    async updateLogis(logi, postData) {
      const { data } = await axios.post(`/update-logis/${logi.idx}`, postData);
      if (data.msg === "ok") {
        logi.currentState = "changed";
        this.$forceUpdate();
        this.$warn("updated");
      }
    },
    modifyId({ idx, zip }) {
      const url = `https://v5.uniauc.com/submit-id/${idx}/${zip}`;
      window.open(url);
    },
    chooseRegion(region) {
      // tab.searchForm.searchEntities.push({
      //   searchField: key,
      //   searchValue: tab.searchForm.region
      // })
    },
    chooseTable(tab) {
      this.searchFields = this.basicFields.filter(
        (field) =>
          field.value.includes(tab.parentTable) ||
          field.value.includes("orders")
      );
    },
    goto(row) {
      location.href = `#${row.item.idx}`;
    },
    deleteField(searchIndex, tab) {
      tab.searchForm.searchEntities.splice(searchIndex, 1);
    },
    initializeOtherFields(tab) {
      const { searchForm } = tab;
      searchForm.statusArr = [];
      searchForm.isMarket = false;
      searchForm.region = "";
    },
    changeField(searchEntity, tab) {
      const input = searchEntity.searchValue.trim();
      if (
        // fedex
        /^\d{12}$/.test(input) ||
        // kpost
        /^6\d{12}$/.test(input)
      ) {
        searchEntity.searchField = "tnum;logis";
      } else if (/ /.test(input)) {
        searchEntity.searchField = "title;orders";
        searchEntity.searchType = "=";
      } else if (/^[a-z]{3}$/.test(input)) {
        searchEntity.searchField = "carrier;logis";
      } else if (/3\d{7}$/.test(input)) {
        searchEntity.searchField = "idx;logis";
      } else if (/5\d{7}$/.test(input)) {
        searchEntity.searchField = "idx;orders";
      } else if (/^B\w{9}$/.test(input)) {
        searchEntity.searchField = "itemCode;orders";
      } else if (/^\d{10}|[0-9-]{26}$/.test(input)) {
        searchEntity.searchField = "feecode;orders";
      } else if (/^(coupang|rakuten)$/.test(input)) {
        searchEntity.searchField = `userid;${tab.parentTable}`;
      }
      if (tab.searchForm.searchEntities.every((entity) => entity.searchValue)) {
        tab.searchForm.searchEntities.push({
          searchField: "logicode;orders",
          searchValue: "",
          searchType: "=",
        });
      }
    },
    getCarrierLink({ carrier, tnum }) {
      return `https://api.resell.glocing.com/track/${tnum}`;
    },
    async snooze() {
      const { data } = await axios.post("/order-list/snoozeBy", {});
    },
    async shipYY() {
      const { data } = await axios.post("/yy-ship");
      this.$warn(data);
    },
    async printAwb(item, carrier) {
      if (carrier === "yye")
        window.open(`https://api.uniauc.com/create-yy-awb/${item.idx}`);
    },
    async extract(tab) {
      this.$bvModal.hide("editModal");
      const { data } = await axios.post("extract-items", {
        idxs: this.checkedItems,
      });
      this.$warn(data);
      this.onSearch(tab);
    },
    async copy(idx, status) {
      if (!confirm("Sure?")) return false;
      const { data } = await axios.post("copy-order", {
        idx,
        status,
      });
      this.$warn(data);
      // this.$router.push(`/order-list/logis?logicode%3Borders=${data.logicode}`);
      // this.onSearch();
    },
    async changeQty(item) {
      this.pushChange("charge", item.charge * (item.cnt / this.newQty));
      this.pushChange("price", item.price * (item.cnt / this.newQty));
      this.pushChange("cnt", this.newQty);
    },
    async split(idx, tab) {
      this.$bvModal.hide("editModal");
      const { data } = await axios.post("split-item", {
        splitQty: this.splitQty,
        idx,
      });
      this.$warn(data);
      this.onSearch(tab);
    },
    getCurrencyUnit(region) {
      switch (region) {
        case "KR":
          return "KR￦";
        case "JP":
          return "JP¥";
        case "CN":
          return "CN¥";
        case "US":
          return "US$";
      }
    },
    async changeHScode(order) {
      const { data } = await axios.post("/edit-order", [
        {
          idx: order.idx,
          hscode: order.hscode,
          category: order.category,
        },
      ]);
      if (data.msg === "success") {
        order.currentState = "changed";
        this.$forceUpdate();
      }
      this.$warn(data.msg);
    },
    async changeArrivesAt(order) {
      const { data } = await axios.post("/edit-order", [
        {
          idx: order.idx,
          arrivesAt: order.arrivesAt,
        },
      ]);
      if (data.msg === "success") {
        order.currentState = "changed";
        this.$forceUpdate();
      }
      this.$warn(data.msg);
    },
    pushParentChange(fee, key, evt, tab) {
      if (["name", "hand", "idno"].includes(key)) fee.idChanged = 1;
    },
    calcShipCost(order) {
      order.proratedShipCost =
        order.estWeight * order.unitCostFx +
        order.baseCostFx / (order.cntTotal || order.cnt);
      console.log(
        order.estWeight,
        order.unitCostFx,
        order.baseCostFx,
        order.cntTotal || order.cnt
      );
      this.$forceUpdate();
    },
    pushChange(order, key, val) {
      if (!val) return false;
      if (key === "url") {
        if (val.includes("taobao.com")) {
          // if(order.value.includes("https://www.taobao.com")){
        }
      }
      this.$forceUpdate();
      order.changed[key] = val.trim();
    },
    pushChangeBulk(key, evt, orders) {
      orders.forEach((order) => {
        order.changed[key] = order[key];
      });
    },
    async onSearchNewTab(tab) {
      // cloning
      this.tabs.unshift({ ...tab });
      this.tabs[0].title = `tab ${this.tabs.length}`;
      await this.chooseTab(this.tabs[0]);
      this.currentTab = 0;
    },
    async onSearch(tab, idx = "") {
      const query = `${tab.parentTable}?searchForm=${JSON.stringify(
        tab.searchForm
      )}&lastIdx=${idx}&currentPage=${this.currentPage}&perPage=${
        this.perPage
      }`;
      if (!tab.searchForm.searchEntities[0].searchValue) {
        this.$warn("Please enter search value");
        return;
      }
      await this.$router.push(query);
      console.log(this.$route.query);
      const parentItems = await this.$getRO(`/order-list/${tab.parentTable}`, {
        params: this.$route.query,
      });
      if (!parentItems.length) {
        this.$warn("No data");
        return;
      }
      parentItems.forEach((item) => {
        item.orders.forEach((order) => {
          order.changed = {
            rate: order.currentFxRate,
          };
          order.currentState = "";
          if (order.arrivesAt) order.arrivesAt = order.arrivesAt.slice(0, 10);
        });
      });
      // tab.parents = parentItems;
      this.tabs[this.currentTab].parents = parentItems;
      this.total = parentItems.length;
      console.log(parentItems);
      return true;
    },
    async onEdit(fee) {
      const post = fee.orders
        .map((order) => ({
          idx: order.idx,
          ...order.changed,
        }))
        .filter((order) => order !== {});
      if (post === {}) alert("nothing changed");
      else {
        await axios.post("/edit-order", post);
      }
      // await this.init();
      this.$bvModal.hide("editModal");
    },
    async onParentEdit(fee, tab) {
      const selectedLogi = fee;
      const dataToSubmit = {};
      this.editFormParent.forEach((group) => {
        dataToSubmit[group.key] = group.value;
      });

      if (tab.parentTable === "logis") {
        const { data } = await axios.post(
          `/edit-logis/${selectedLogi.idx}/${selectedLogi.zip}?status=${selectedLogi.status}`,
          dataToSubmit
        );
        console.log("warning");
        this.$warn(data.message);
        if (data.error) {
          return false;
        }
      }
      if (tab.parentTable === "fees")
        await axios.post(`/edit-fees/${selectedLogi.idx}`, dataToSubmit);
      // await this.init();
      this.editFormParent.forEach((group) => {
        selectedLogi[group.key] = group.value;
      });
      this.$bvModal.hide("parentEditModal");
      return true;
    },
    parentEdit(fee) {
      this.openModalIdx = fee.idx;
      if (fee.status === -1) {
        this.editFormParent = [
          { key: "name", text: "Name", value: "" },
          { key: "hand", text: "Phone", value: "" },
          { key: "adr1", text: "Addresss 1", value: "" },
          { key: "adr2", text: "Addresss 2", value: "" },
          { key: "zip", text: "Zip code", value: "" },
          { key: "idno", text: "ID Number", value: "" },
        ];
      } else {
        this.editFormParent = [
          { key: "name", text: "Name", value: "" },
          { key: "hand", text: "Phone", value: "" },
          { key: "adr1", text: "address 1", value: "" },
          { key: "adr2", text: "address 2", value: "" },
          { key: "city", text: "city", value: "" },
          { key: "province", text: "province", value: "" },
          { key: "zip", text: "zip code", value: "" },
          { key: "countryCode", text: "country code", value: "" },
          { key: "idno", text: "ID Number", value: "" },
          { key: "tnum", text: "HBL No", value: "" },
          { key: "email", text: "email", value: "" },
          { key: "desc", text: "Packing memo", value: "" },
          { key: "weight", text: "weight", value: "" },
          { key: "carrier", text: "carrier", value: "" },
        ];
      }
      this.editFormParent.forEach((field) => {
        field.value = fee[field.key];
      });
      this.$bvModal.show("parentEditModal");
    },
    edit(order) {
      // this.currentOrder = tab.parents[feeIndex].orders[orderIndex];
      order.changed = {};
      this.openModalIdx = order.idx;
      this.$bvModal.show(`editModal`);
    },
    bulkEdit(fieldKey, fee, tab) {
      tab.fee.orders.forEach((order) => {
        order.changed = {};
      });
      this.openBulkModalKey = fieldKey;
      this.$bvModal.show("bulkEditModal");
    },
    async submit(stateCode, params, orderOrFee, orders, fee) {
      if (!confirm("proceed?")) return false;
      // if (
      //   stateCode === "retry" &&
      //   !confirm("sure?")
      // ) {g
      //   return false;
      // }
      const { idxs, feecode } = params;
      if (fee) await this.onEdit(fee);

      const statuses = {
        partialRetry: 0,
        retry: 0,
        confirm: 0,
        bought: 2,
        arrived: 4,
        shipped: 5,
        cancel: 6,
        canceled: 7,
        delete: 7,
      };
      const status = statuses[stateCode];

      const { data } = await axios.post("/change-status", {
        safeMode:
          !this.$route.query || this.$route.query["status;orders"] === "1"
            ? 1
            : 0,
        status,
        stateCode,
        idxs,
        // logicode:orderOrFee.logicode,
        feecode,
        uid: orderOrFee.itemCode,
        countryId: orderOrFee.countryId,
        userid: orderOrFee.userid,
        orders: orders.map(({ idx, feecode, status }) => ({
          idx,
          feecode,
          status,
        })),
      });
      if (data.msg === "ok") {
        orderOrFee.currentState = stateCode;
        orderOrFee.status = status;
        this.$forceUpdate();
      }
      this.$warn(data.msg);
    },
    async init() {
      const { data: searchFields } = await axios.get("/get-order-fields");
      const { data: rates } = await axios.get("/get-currency-rates");
      this.rates = rates;
      this.CNYUSD = this.rates.find((rate) => rate.fromCode === "CNY").rate;
      console.log(rates);
      const searchFieldsOptions = searchFields.map((key) => ({
        text: key,
        value: key,
      }));
      this.basicFields.push(...searchFieldsOptions);
      // this.chooseTab(this.tabs[0]);
    },
    async chooseTab(tab) {
      await this.onSearch(tab);
      this.chooseTable(tab);
    },
    async onSubmit() {
      await this.$api.post("/stock", {
        stockData: this.stockData,
      });
      this.stockData = "";
      this.init();
    },
  },
};
</script>
<style>
.modal-fullscreen .modal-dialog {
  max-width: 100%;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  display: flex;
  position: fixed;
  z-index: 100000;
}

@media (min-width: 2992px) .modal-lg {
  max-width: auto !important;
}

@media (min-width: 576px) .modal-dialog {
  max-width: auto !important;
}
</style>
